<!--
 * @Author: 李明明
 * @Date: 2022-01-04 15:11:44
 * @LastEditTime: 2022-10-21 11:00:11
 * @LastEditors: 李明明
 * @Description: 底部
 * @FilePath: /证书查询/zs-query/src/components/foot.vue
 * 代码版权方：'政府采购信息网'
-->
<template>
  <footer>
    <div class="foot_txt">
      <p>技术支持：政府采购信息网</p>
      <div class="foot_right">
        <p>政府采购信息报社 版权所有 地址：北京市大兴区欣宝街2号院一区7号楼（鸿坤金融谷2号地A3楼）</p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
footer {
  background: #418ec6;
  height: 56px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  .foot_txt {
    width: 800px;
    margin: 0 auto;
    p {
      color: #ffffff;
      font-size: 12px;
      line-height: 56px;
      margin-top: 0;
      display: inline-block;
    }
    .foot_right {
      float: right;
      a{
        line-height: 56px;
        color: #ffffff;
        font-size: 12px;
        margin-right: 20px;
      }
    }
  }
}
</style>
